export const environment = {
  production: true,
  envName: 'prod',
  autoClub: 'AANT',
  veriskNetworkUsername: '',
  veriskNetworkPassword: '',
  addressify: {
    baseUrl: 'https://api.addressify.com.au',
    apiKey: '0E4644FE-85E4-4685-930F-DBF5F8629DFA',
  },

  baseAPIUrl:
    'https://ca-qa-tmma-ltx-qj-api.icymeadow-5a31b099.australiaeast.azurecontainerapps.io/api/',

  verisk: {
    username: 'BB3TokioMarineAANTAusTravelUAT',
    vrrInternal:
      'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIEFBTlQgQXVzIFRyYXZlbCBCQjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.TCowkhi2ikRqmyZvxnvbxqXEHn5ygY0noDuUEczD8kE',
  },
  DMSAPI: {
    baseUrl: 'https://dmsapiservices.azurewebsites.net/',
    apiKey: 'A3886FC2E95C962113161EBBC6F6E',
  },

  PASUrl: 'https://pasqa.tmaainsurance.com',
};
